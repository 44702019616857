// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // baseURL: 'https://system.freshfishbasket.in/api/v1/',
 // baseURL: 'http://localhost/ffb-dev/public/api/v1/',
//  baseURL: 'https://www.webappfactory.co/ffbdev/public/api/v1/',
//  siteURL: 'http://webappfactory.co/ffbdev/public/'
  // baseURL: 'https://system.freshfishbasket.in/api/v1/',
  // siteURL: 'https://system.freshfishbasket.in/'
  baseURL: 'https://system.freshfishbasket.in/api/v1/',
  siteURL: 'https://system.freshfishbasket.in/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
